/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "apie547460c",
            "endpoint": "https://eg5ac3ps2g.execute-api.eu-central-1.amazonaws.com/main",
            "region": "eu-central-1"
        },
        {
            "name": "apicontacts",
            "endpoint": "https://eag9j202l0.execute-api.eu-central-1.amazonaws.com/main",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
