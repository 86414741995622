import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import {
  Carousel,
  CarouselItem
} from 'reactstrap';
import './App.css';
import deflag from "./assets/images/de.png";
import ruflag from "./assets/images/ru.png";
import enflag from "./assets/images/en.png";
import Acceptance from './components/Acceptance';
import WhyUs from './components/WhyUs';
import Performance from './components/Performance';
import Factsheet from './components/Factsheet';
import Disclosures from './components/Disclosures';
import Contacts from './components/Contacts';
//import { ReactComponent as PerformanceSVG } from './performance.svg';

class Accelerator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      'activeIndex': 0,
      'animating': false,
      'lang': 'en',
      'fading_lang': 'en',
      'fadeout': 0,
      'accepted': 0
    }
    this.titles = {
      'en': 'Blackfort Daily Quant Accelerator I',
      'de': 'Blackfort Daily Quant Accelerator I',
      'ru': 'Ежедневный доходный ускоритель Блекфорт I'
    }
    this.subtitles = {
      'en': 'Positive return generation on each single trading day',
      'de': 'Positive Erträge an jedem einzelnen Handelstag',
      'ru': 'Ваша прибыль каждый торговый день'
    }
    this.buttons = {
      'en': ['Why us','Performance Report','Factsheet','Disclosures'],
      'de': ['Warum wir','Leistungsbericht','Faktenblatt','Hinweis'],
      'ru': ['Об ускорителе','Отчет','Детали','Комментарии']      
    }
  }

  // carousel controls ---------------------------------------------------------\
  setActiveIndex = (nextIndex) => {
    this.setState({'activeIndex': nextIndex});
  }

  setAnimating = (nextIndex) => {
    this.setState({'animating': nextIndex});
  }

  goToIndex = (newIndex) => {
    if (this.state.animating) return;
    this.setActiveIndex(newIndex);
  }

  switch_lang = (lang) => {
    if (lang != this.state.lang) {
      this.setState({
          "fading_lang": lang,
          "lang": lang,
        });
    }
  }

  render () {
    if (this.state.accepted) {
    return (
      <div className="tw-pl-4 tw-pr-8 lg:tw-px-8 tw-py-8 tw-bg-white tw-shadow-lg tw-rounded-lg tw-max-w-screen-lg tw-w-full tw-min-w-min">
      <div className="tw-ease-in tw-flex tw-flex-col tw-items-center">
      <Helmet>
        <title>{this.titles[this.state.lang]}</title>
      </Helmet>
      <div className="tw-flex tw-flex-row tw-justify-center md:tw-justify-end tw-w-full tw-h-5">
        <img src={deflag} className={this.state.fading_lang == 'de' ? "tw-inline tw-mr-1 tw-w-8" : "tw-transition-opacity tw-duration-200 tw-ease-in tw-inline tw-mr-1 tw-opacity-20 tw-cursor-pointer tw-w-7"} onClick={()=>{this.switch_lang('de')}} />
        <img src={enflag} className={this.state.fading_lang == 'en' ? "tw-inline tw-mr-1 tw-w-8" : "tw-transition-opacity tw-duration-200 tw-ease-in tw-inline tw-mr-1 tw-opacity-20 tw-cursor-pointer tw-w-7"} onClick={()=>{this.switch_lang('en')}} />
        <img src={ruflag} className={this.state.fading_lang == 'ru' ? "tw-inline tw-mr-1 tw-w-8" : "tw-transition-opacity tw-duration-200 tw-ease-in tw-inline tw-mr-1 tw-opacity-20 tw-cursor-pointer tw-w-7"} onClick={()=>{this.switch_lang('ru')}} />
      </div>
      <div className={this.state.fadeout ? "tw-transition-opacity tw-duration-500 tw-ease-in tw-w-full md:tw-w-3/5 tw-opacity-50" : "tw-w-full md:tw-w-3/5"} >
      <h1 className="tw-text-2xl lg:tw-text-4xl tw-font-bold tw-text-center tw-my-8" style={{
        borderBottom: "1px solid #ebebeb"}}>{this.titles[this.state.lang]}</h1>

      <div className="col-sm-12 text-center mt-0 mb-1">
      <p>{this.subtitles[this.state.lang]}</p>
      </div>

      <div className="tw-flex tw-flex-row tw-justify-center text-center tw-text-sm lg:tw-text-base tw-flex-wrap">
          <div className={this.state.activeIndex==0 ? "btn-link tw-shadow-lg tw-p-2 tw-rounded" : "btn-link tw-cursor-pointer tw-p-2 tw-rounded"} style={{"color": "#ffa500"}} onClick={()=>{this.goToIndex(0)}}>{this.buttons[this.state.lang][0]}</div>
          <div className={this.state.activeIndex==1 ? "btn-link tw-shadow-lg tw-p-2 tw-rounded" : "btn-link tw-cursor-pointer tw-p-2 tw-rounded"} style={{"color": "#ffa500"}} onClick={()=>{this.goToIndex(1)}}>{this.buttons[this.state.lang][1]}</div>
          <div className={this.state.activeIndex==2 ? "btn-link tw-shadow-lg tw-p-2 tw-rounded" : "btn-link tw-cursor-pointer tw-p-2 tw-rounded"} style={{"color": "#ffa500"}} onClick={()=>{this.goToIndex(2)}}>{this.buttons[this.state.lang][2]}</div>
          <div className={this.state.activeIndex==3 ? "btn-link tw-shadow-lg tw-p-2 tw-rounded" : "btn-link tw-cursor-pointer tw-p-2 tw-rounded"} style={{"color": "#ffa500"}} onClick={()=>{this.goToIndex(3)}}>{this.buttons[this.state.lang][3]}</div>
      </div>

      <Carousel
      activeIndex={this.state.activeIndex}
      next={()=>{}}
      previous={()=>{}}
      autoPlay={false}
      controls={false}
      pause={false}
      interval={false}>
      <CarouselItem
        onExiting={() => this.setAnimating(false)}
        onExited={() => this.setAnimating(false)}>

        <WhyUs lang={this.state.lang} />

      </CarouselItem>
      <CarouselItem
        onExiting={() => this.setAnimating(false)}
        onExited={() => this.setAnimating(false)}>

        <Performance lang={this.state.lang} />

      </CarouselItem>
      <CarouselItem
        onExiting={() => this.setAnimating(false)}
        onExited={() => this.setAnimating(false)}>

        <Factsheet lang={this.state.lang} />

      </CarouselItem>
      <CarouselItem
        onExiting={() => this.setAnimating(false)}
        onExited={() => this.setAnimating(false)}>

        <Disclosures lang={this.state.lang} />
        

      </CarouselItem>
    </Carousel>

    <Contacts lang={this.state.lang} title={this.titles[this.state.lang]} subtitle={this.subtitles[this.state.lang]} />
    </div>
    </div>
    </div>
    )
    }
    else {
      return (
        <Acceptance lang={this.state.lang} ui={this}/>
      )
    }
  }
}

function App() {

  return (
    <div className="tw-text-sm lg:tw-text-base tw-p-2 lg:tw-p-8 tw-flex tw-flex-row tw-justify-start">
      <Accelerator />
    </div>
  );
}

export default App;
