import React from 'react';
import { ReactComponent as PerfSVGInside } from './perf.svg';
import { ReactComponent as PerfSVGInsideRU } from './perf_ru.svg';
import { ReactComponent as PerfSVGInsideDE } from './perf_de.svg';
import * as d3 from "d3";

class PerfSVG extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.set_scripts();
  }

  componentDidUpdate() {
    this.set_scripts();
  }

  set_scripts = () => {
    d3.select("#perf_figure").selectAll("rect")
    .on("mousemove",
        function () {
            const id = d3.select(this).attr('id');
            if (id.indexOf("cl") !== -1) {
                d3.select("#"+id.slice(0,-2) + "lb").style("text-decoration", "none").style("opacity", "1");
                d3.select("#"+id.slice(0,-2) + "l").style("text-decoration", "underline");
                d3.select("#"+id.slice(0,-2) + "l").style("opacity", "1");
                d3.select("#"+id.slice(0,-2) + "r").style("fill", "#808080");
                d3.select("#"+id.slice(0,-2) + "m").style("stroke-width", "7.5");
            };
        })
    .on("mouseout",
        function () {
            const id = d3.select(this).attr('id');
            if (id.indexOf("cl") !== -1) {
                d3.select("#"+id.slice(0,-2) + "lb").style("text-decoration", "none").style("opacity", "0");
                d3.select("#"+id.slice(0,-2) + "l").style("text-decoration", "none");
                d3.select("#"+id.slice(0,-2) + "l").style("opacity", "0.25");
                d3.select("#"+id.slice(0,-2) + "r").style("fill", "#ebebeb");
                d3.select("#"+id.slice(0,-2) + "m").style("stroke-width", "2.25");
            };
        });
  }

  render () {
    return (
      <div className="tw-flex tw-flex-col tw-items-center tw-ml-2">
      <div className="tw-w-full tw-py-8 tw-px-2 tw-bg-white tw-rounded-2xl border">
      <div style={{
        position:"relative",
        paddingBottom:"34.55%",
        height:"0",
        widht:"100%",
        backgroundColor:"#ffffff"}} className="tw-cursor-pointer tw-w-full">
        {{
          'en': <PerfSVGInside id="perf_figure" />,
          'ru': <PerfSVGInsideRU id="perf_figure" />,
          'de': <PerfSVGInsideDE id="perf_figure" />
          }[this.props.lang]}
      </div>
      </div>
      </div>
    );
  }

}

export default PerfSVG;